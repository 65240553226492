import React from "react"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <div className="text-center m-5">
      <h1>Oops, that's a 404 not found</h1>
      <p>Sorry, but the page you were trying to view does not exist.</p>
    </div>
  </Layout>
)
